import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import coreui from '@coreui/vue'
import VueCookies from 'vue-cookies'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import withUUID from 'vue-uuid'

import { faArrowLeft, faCircleExclamation, faPlus, faTrashCan, faMobileScreen, faSignal, faPhone, faReceipt, faCaretDown, faCaretRight, faR, faMessage, faEnvelopesBulk } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

import 'bootstrap/dist/css/bootstrap.min.css'
import './main.scss'

library.add(faArrowLeft)
library.add(faCircleExclamation)
library.add(faCopy)
library.add(faPlus)
library.add(faTrashCan)
library.add(faMobileScreen)
library.add(faSignal)
library.add(faPhone)
library.add(faReceipt)
library.add(faCaretDown)
library.add(faCaretRight)
library.add(faR)
library.add(faMessage)
library.add(faEnvelopesBulk)

withUUID(
  createApp(App).use(store).use(router).use(coreui).use(VueCookies).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
)
