import moment from 'moment-timezone'
import { axiosInstance as axios, axiosGraphQlRequest } from '../../utils/axiosInstances'
import {
  createDeviceQuery,
  deleteDeviceQuery,
  getDeviceQuery,
  getEventDataRecordsByAccountQuery
} from '../modules/queries'
import { createDevice, deleteDevice } from './utils/persistence/mutations'
import { graphQLUrl, persistenceUrl } from '@/store/common'
import { buildGraphQlPayload } from '@/utils/graphql_helpers'
import { buildReadableAllocationsOrBalances } from './utils/unifiedBalances'
import { subscribeToPlan } from './utils/totogi/mutations'
import { PLANAI_DATA_API_URL } from '@/utils/constants'

export default {
  namespaced: true,
  state: {
    demoDeviceEdrEdges: [],
    devices: [],
    billingEDRs: [],
    accountEDRs: [],
    hasMoreBillingEDRs: false,
    billingEDRsEndCursor: null,
    hasMoreAccountEDRs: false,
    accountEDRsEndCursor: null,
    loadingDevice: false,
    deviceLoaded: false,
    edrsLoaded: false,
    edrsLoading: false,
    loadingPercent: 0,
    loadingStatus: '',
    planAIOffers: [],
    planAIOffersLoading: false
  },
  getters: {
    zainEDRFromTimestamp () {
      const zainStartDate = moment('2024-03-07T00:00:00+02:00').tz('UTC')
      const oneWeekBack = moment().tz('UTC').hour(0).minute(0).add(-7, 'days')
      return (oneWeekBack.isAfter(zainStartDate) ? oneWeekBack : zainStartDate).toISOString()
    }
  },
  mutations: {
    setBillingEDRs (state, edrs) {
      state.billingEDRs = edrs
    },
    setAccountEDRs (state, edrs) {
      state.accountEDRs = edrs
    },
    setLoadingDevice (state, newValue) {
      state.loadingDevice = newValue
    },
    setLoadingPercent (state, newValue) {
      state.loadingPercent = newValue
    },
    setLoadingStatus (state, newValue) {
      state.loadingStatus = newValue
    },
    setDeviceLoaded (state, newValue) {
      state.deviceLoaded = newValue
    },
    setEdrsLoaded (state, newValue) {
      state.edrsLoaded = newValue
    },
    setEdrsLoading (state, newValue) {
      state.edrsLoading = newValue
    },
    setDemoAccount (state, account) {
      state.demoAccount = account
    },
    setPlanAIOffers (state, newValue) {
      state.planAIOffers = newValue
    },
    setPlanAIOffersLoading (state, newValue) {
      state.planAIOffersLoading = newValue
    },
    setHasMoreBillingEDRs (state, newValue) {
      state.hasMoreBillingEDRs = newValue
    },
    setHasMoreAccountEDRs (state, newValue) {
      state.hasMoreAccountEDRs = newValue
    },
    setBillingEDRsEndCursor (state, newValue) {
      state.billingEDRsEndCursor = newValue
    },
    setAccountEDRsEndCursor (state, newValue) {
      state.accountEDRsEndCursor = newValue
    },
    setDemoAccountBalance (state, balance) {
      state.demoAccountBalance = balance
    },
    setDemoAccountAllocations (state, allocations) {
      state.demoAccountAllocations = allocations
    },
    setDemoAccountPlans (state, plans) {
      state.demoAccountPlans = plans
    },
    setDemoDevice (state, device) {
      state.demoDevice = device
    },
    setDemoDeviceEdrEdges (state, newValue) {
      state.demoDeviceEdrEdges = newValue
    },
    setDemoDeviceRefreshInterval (state, newValue) {
      state.demoDeviceRefreshInterval = newValue
    },
    setDevices (state, newValue) {
      state.devices = newValue
    },
    setN28NotificationRefreshInterval (state, newValue) {
      state.n28NotificationRefreshInterval = newValue
    }
  },
  actions: {
    async getPlanAIOffers (context, { deviceId }) {
      const result = (await axios.get(
        `${PLANAI_DATA_API_URL}/device_offers?device_id=${deviceId}&provider_id=${context.rootState.providerId}`,
        {
          headers: {
            Authorization: `${context.rootState.idToken}`
          }
        }
      )).data
      if (result.errorCode) {
        throw result
      }
      console.log('Retrieved plan AI offers', result)
      context.commit('setPlanAIOffers', result?.device_offers || [])
      return (result?.device_offers || [])
    },
    async delete (context, { deviceId, accountId }) {
      const result = await axios.post(
        graphQLUrl,
        deleteDeviceQuery(
          deviceId,
          accountId,
          context.rootState.providerId
        ), {
          headers: {
            Authorization: `${context.rootState.idToken}`
          }
        }
      )
      if (!result?.data?.data?.deleteDevice?.deletedDeviceId) {
        return
      }
      await axios.post(
        persistenceUrl,
        buildGraphQlPayload(deleteDevice, {
          input: {
            accountId,
            deviceId,
            deviceName: '-',
            providerId: context.rootState.providerId
          }
        }),
        context.rootGetters.graphQLHeaders
      )
    },
    async create (context, { deviceId, accountId }) {
      const result = await axios.post(
        graphQLUrl,
        createDeviceQuery(
          deviceId,
          accountId,
          context.rootState.providerId
        ),
        {
          headers: {
            Authorization: `${context.rootState.idToken}`
          }
        }
      )
      if (!result?.data?.data?.createDevice?.device) {
        return
      }
      await axios.post(
        persistenceUrl,
        buildGraphQlPayload(createDevice, {
          input: {
            accountId,
            deviceId,
            providerId: context.rootState.providerId
          }
        }),
        context.rootGetters.graphQLHeaders
      )
    },
    async getDevice (context, { deviceId }) {
      const result = (await axios.post(
        graphQLUrl,
        getDeviceQuery(
          deviceId,
          context.rootState.providerId
        ),
        context.rootGetters.graphQLHeaders
      )).data.data.getDevice
      if (result.errorCode) {
        throw result
      }
      return result
    },
    async getDemoDevice (context, { deviceId, fromTimestamp, toTimestamp }) {
      console.log(`Loading demo device ${deviceId}`)
      context.commit('setLoadingDevice', true)
      context.commit('setEdrsLoaded', false)
      context.commit('setLoadingPercent', 0)
      context.commit('setLoadingStatus', 'Loading Device Info')
      try {
        context.commit('setDemoDevice', await context.dispatch('getDevice', { deviceId }))
      } catch (err) {
        if (err.errorCode) {
          throw err
        }
      }
      if (!context.rootState.settings.settingsLoaded) {
        await context.dispatch('settings/loadSettings', null, { root: true })
      }
      const device = context.state.demoDevice
      context.commit('setDemoAccount', device.account)
      /* const accountBalance = await context.dispatch('getAccount', { accountId: device.account.id }, { root: true })
      accountBalance.serviceBalances = await buildReadableAllocationsOrBalances(accountBalance.serviceBalances, 'balance')
      context.commit('setDemoAccountBalance', accountBalance)
      if (!context.rootState.allAvailablePlanInformation || !context.rootState.allAvailablePlanInformation.length) {
        await context.dispatch('planVersions/getPlanInformation', null, { root: true })
      } */
      context.commit('setLoadingDevice', false)
      context.commit('setDeviceLoaded', true)
      context.commit('setBillingEDRs', [])
      context.commit('setHasMoreBillingEDRs', false)
      context.commit('setBillingEDRsEndCursor', null)
      context.commit('setAccountEDRs', [])
      context.commit('setHasMoreAccountEDRs', false)
      context.commit('setAccountEDRsEndCursor', null)
      context.commit('setLoadingPercent', 0)
      context.commit('setLoadingStatus', '')
      context.commit('setEdrsLoaded', false)
      return device
    },
    async loadEDRs (context, { fromTimestamp, toTimestamp }) {
      context.commit('setEdrsLoading', true)
      context.commit('setBillingEDRs', [])
      context.commit('setHasMoreBillingEDRs', false)
      context.commit('setBillingEDRsEndCursor', null)
      context.commit('setAccountEDRs', [])
      context.commit('setHasMoreAccountEDRs', false)
      context.commit('setAccountEDRsEndCursor', null)
      context.commit('setLoadingPercent', 0)
      context.commit('setLoadingStatus', 'Loading Billing EDRs')
      await context.dispatch('loadBillingEDRs', { fromTimestamp, toTimestamp })
      context.commit('setLoadingPercent', 50)
      context.commit('setLoadingStatus', 'Loading Account EDRs')
      await context.dispatch('loadAccountEDRs', { fromTimestamp, toTimestamp })
      context.commit('setLoadingPercent', 0)
      context.commit('setLoadingStatus', '')
      context.commit('setEdrsLoading', false)
      context.commit('setEdrsLoaded', true)
    },
    async loadBillingEDRs (context, { fromTimestamp, toTimestamp }) {
      if (context.state.loadingDevice) {
        return
      }
      const result = (await axios.post(
        graphQLUrl,
        getEventDataRecordsByAccountQuery(
          context.rootState.providerId,
          context.state.demoDevice.account.id,
          'BILLING',
          context.state.billingEDRsEndCursor,
          fromTimestamp,
          toTimestamp
        ),
        context.rootGetters.graphQLHeaders
      )).data.data.getEventDataRecordsByAccount
      if (context.state.loadingDevice) {
        return
      }
      const billingEDRs = result.edges.map(edge => {
        edge.node.eventData = JSON.parse(edge.node.eventData)
        edge.node.customData = JSON.parse(edge.node.customData)
        return edge.node
      })
      try {
        console.log('Multiple charges Billing EDRs', billingEDRs.filter(edr => edr.customData?.charginginformation?.multipleunitinformation[0]?.charges?.length > 1))
      } catch (err) {
        console.log('Multiple charges Billing EDRs - unable to filter and show', err)
      }
      const pageInfo = result.pageInfo
      context.commit('setBillingEDRs', [...context.state.billingEDRs, ...billingEDRs])
      context.commit('setHasMoreAccountEDRs', pageInfo.hasNextPage)
      context.commit('setBillingEDRsEndCursor', pageInfo.endCursor)
      if (context.state.loadingDevice) {
        return
      }
      console.log('Billing EDRs', pageInfo.hasNextPage, pageInfo.endCursor)
      if (context.state.hasMoreBillingEDRs || billingEDRs.length >= 25) {
        context.dispatch('loadBillingEDRs', { fromTimestamp, toTimestamp })
      }
    },
    async loadAccountEDRs (context, { fromTimestamp, toTimestamp }) {
      if (context.state.loadingDevice) {
        return
      }
      const result = (await axios.post(
        graphQLUrl,
        getEventDataRecordsByAccountQuery(
          context.rootState.providerId,
          context.state.demoDevice.account.id,
          'ACCOUNT',
          context.state.accountEDRsEndCursor,
          fromTimestamp,
          toTimestamp
        ),
        context.rootGetters.graphQLHeaders
      )).data.data.getEventDataRecordsByAccount
      if (context.state.loadingDevice) {
        return
      }
      const accountEDRs = result.edges.map(edge => {
        edge.node.eventData = JSON.parse(edge.node.eventData)
        edge.node.customData = JSON.parse(edge.node.customData)
        return edge.node
      })
      const pageInfo = result.pageInfo
      context.commit('setAccountEDRs', [...context.state.accountEDRs, ...accountEDRs])
      context.commit('setHasMoreBillingEDRs', pageInfo.hasNextPage)
      context.commit('setAccountEDRsEndCursor', pageInfo.endCursor)
      if (context.state.loadingDevice) {
        return
      }
      if (context.state.hasMoreAccountEDRs || accountEDRs.length >= 25) {
        context.dispatch('loadAccountEDRs', { fromTimestamp, toTimestamp })
      }
    },
    async resubscribePlan (context, { planSubscriptionId, planVersionId }) {
      console.log('Resubscribing to plan')
      await context.dispatch('clearDemoDeviceRefresh')
      await context.dispatch('clearN28NotificationSubscription')
      await context.dispatch('loadDemoDevicePlans', { refresh: true })
      console.log(`Cancelling plan ${planSubscriptionId} - ${planVersionId}`)
      await context.dispatch('account/invokeCancelPlanSubscription', {
        accountId: context.state.demoAccount.id,
        planSubscriptionId
      }, { root: true })
      await new Promise(resolve => setTimeout(resolve, 1000))
      await axiosGraphQlRequest(
        graphQLUrl,
        subscribeToPlan,
        {
          input: {
            accountId: context.state.demoAccount.id,
            providerId: context.rootState.providerId,
            planVersionId: planVersionId
          }
        },
        context.rootGetters.graphQLHeaders
      )
      await context.dispatch('loadDemoDevicePlans', { refresh: true })
      console.log('Resubscribing to plan complete')
      context.dispatch('refreshDemoDeviceBalanceAndInfo')
      await context.dispatch('initiateDemoDeviceRefresh')
    },
    async provisionNewPlanDemoDevice (context) {
      console.log('Provisoning new demo device')
      await context.dispatch('clearDemoDeviceRefresh')
      await context.dispatch('clearN28NotificationSubscription')
      await context.dispatch('loadDemoDevicePlans', { refresh: true })
      const latestPlanSubscription = await context.dispatch('account/latestPlanSubscription', { activePlans: context.state.demoAccountPlans }, { root: true })
      console.log('Latest susbcription', latestPlanSubscription)
      if (latestPlanSubscription) {
        console.log('Cancelling plan')
        console.log(context.state.demoAccount, context.state.demoAccount.id, latestPlanSubscription.planVersionId, latestPlanSubscription, context.state.demoAccountPlans)
        await context.dispatch('account/cancelPlanSubscription', {
          accountId: context.state.demoAccount.id,
          planVersionId: latestPlanSubscription.planVersionId,
          activePlans: context.state.demoAccountPlans
        }, { root: true })
        await new Promise(resolve => setTimeout(resolve, 1000))
      }
      await context.dispatch('account/subscribeToCurrentPlanVersion', { accountId: context.state.demoAccount.id }, { root: true })
      await context.dispatch('loadDemoDevicePlans', { refresh: true })
      console.log('Provisioning new plan complete', context.state.demoAccountAllocations.map(a => a.subscriptionStartedAt.format()), context.state.demoAccountPlans.map(p => p.planSubscriptionId))
      context.dispatch('refreshDemoDeviceBalanceAndInfo')
      await context.dispatch('initiateDemoDeviceRefresh')
    },
    async refreshDemoDeviceBalanceAndInfo (context) {
      console.log('Refreshing Demo Device information')
      const accountBalance = await context.dispatch('getAccount', { accountId: context.state.demoDevice.account.id }, { root: true })
      accountBalance.serviceBalances = await buildReadableAllocationsOrBalances(accountBalance.serviceBalances, 'balance')
      /*
        Example Service balances:
        {
          id: "voice-all-net.86acc42b-981d-4985-87f5-4f93eae5be91",
          name: "Voice All Net",
          readableValue: "0 mins",
          type: "Voice",
          uuid: "86acc42b-981d-4985-87f5-4f93eae5be91",
          value: "0"
        }
        {
          "id": "data.1GB.7e884ec2-c607-401d-9a26-08564268b303",
          "name": "Data",
          "readableValue": "1024 MB",
          "type": "Data",
          "uuid": "7e884ec2-c607-401d-9a26-08564268b303",
          "value": "1073741824"
        }
      */
      for (const activePlanVersion of accountBalance.activePlanVersions) {
        for (const planService of activePlanVersion.planVersion.planServices) {
          for (const serviceBalance of accountBalance.serviceBalances) {
            if (planService.balanceName === serviceBalance.id) {
              serviceBalance.planId = activePlanVersion.planVersion.plan.id
              serviceBalance.planName = activePlanVersion.planVersion.plan.name
              serviceBalance.planSubscriptionId = activePlanVersion.planSubscriptionId
              serviceBalance.from = activePlanVersion.from
              serviceBalance.to = activePlanVersion.to
              serviceBalance.planVersionName = activePlanVersion.planVersion.version
              serviceBalance.planVersionId = activePlanVersion.planVersion.id
              break
            }
          }
        }
      }
      console.log('Account Balances')
      console.table(accountBalance.serviceBalances.concat([{ name: 'Monetary', value: accountBalance.monetaryBalance }]))
      console.log('Active plan versions')
      console.table(accountBalance.activePlanVersions)
      context.commit('setDemoAccountBalance', accountBalance)
      context.dispatch('notifications/update', { accountId: context.state.demoAccount.id, deviceId: context.state.demoDevice.id }, { root: true })
      const mappedEdrEdges = await context.dispatch('getAndSummarizeEdrs', { deviceId: context.state.demoDevice.id }, { root: true })
      context.commit('setDemoDeviceEdrEdges', mappedEdrEdges)
      try {
        const device = await context.dispatch('getDevice', { deviceId: context.state.demoDevice.id })
        context.commit('setDemoAccount', device.account)
      } catch (err) {
        if (err.errorCode) {
          throw err
        }
      }
    }
  }
}
