<template>
  <div>
    <CContainer class="text-start bg-white shadow-sm py-5 px-4 mb-4">
      <h1 class="display-4">
        Customer 360
      </h1>
      <div class="text-secondary">Enter Device ID and click Lookup to begin</div>
      <br />
      <CRow>
        <CCol>
          <label>Device ID</label>
          <div class="d-flex">
            <div class="flex-grow-0" style="width: 300px">
              <CInputGroup>
                <CFormInput
                  type="text"
                  placeholder=""
                  :style="{ maxWidth: '300px' }"
                  :disabled="edrsLoading || isDeviceLoading || updatingBalance || configLoading"
                  v-model="deviceId"
                />
                <CButton type="button" color="primary" @click="loadDevice" :disabled="isDeviceLoading || configLoading || edrsLoading">
                  {{isDeviceLoading ? 'Loading...' : (configLoading ? 'Config Loading...' : 'Lookup')}}
                </CButton>
              </CInputGroup>
            </div>
            <div class="flex-grow-1 ps-2 py-2" v-if="isDeviceLoading || edrsLoading">
              <CProgress :value="deviceLoadingPercent">
                <CProgressBar :value="deviceLoadingPercent" class="fw-semibold overflow-visible px-2">{{ deviceLoadingStatus }}</CProgressBar>
              </CProgress>
            </div>
            <div class="flex-grow-1 ps-2" v-else>
              <CAlert class="px-2" style="padding-top: 8px; padding-bottom: 8px;" color="warning" v-if="deviceIdError">
                <font-awesome-icon icon="fa-solid fa-circle-exclamation" /> {{deviceIdError}}
              </CAlert>
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow class="pt-5" v-if="deviceLoaded">
        <CCol>
          <CRow>
            <CCol>
              <CCard class="detail-card" style="min-height: 100%;">
                <CCardHeader>
                  <font-awesome-icon icon="fa-solid fa-mobile-screen" />
                  &nbsp;Device Info
                </CCardHeader>
                <CCardBody>
                  <CCardText>
                    Device ID&nbsp;
                    <TotBadge :copyValue="deviceIdText">{{deviceIdText}}</TotBadge>
                  </CCardText>
                  <CCardText>
                    Account ID&nbsp;
                    <TotBadge :copyValue="accountIdText">{{accountIdText}}</TotBadge>
                  </CCardText>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="pt-3">
            <CCol sm="5">
              <CCard class="detail-card" style="min-height: 100%;">
                <CCardHeader>
                  <CNav class="pb-3">
                    <CNavItem>
                      <CNavLink :style="{'border-bottom-width': '2px !important', cursor: 'pointer'}" :class="{'fw-medium': selectedPlanVersionList === 'active', 'border-bottom': selectedPlanVersionList === 'active', 'border-primary': true}" @click="selectedPlanVersionList = 'active'">
                        Active Plans - {{ activePlanSubscriptionsCount }}
                      </CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink :style="{'border-bottom-width': '2px !important', cursor: 'pointer'}"  :class="{'fw-medium': selectedPlanVersionList === 'archived', 'border-bottom': selectedPlanVersionList === 'archived', 'border-primary': true}" @click="selectedPlanVersionList = 'archived'">
                        Archived Plans - {{ archivedPlanSubscriptionsCount }}
                      </CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink :style="{'border-bottom-width': '2px !important', cursor: 'pointer'}"  :class="{'fw-medium': selectedPlanVersionList === 'planai', 'border-bottom': selectedPlanVersionList === 'planai', 'border-primary': true}" @click="selectedPlanVersionList = 'planai'">
                        PlanAI - {{ planAIOffersCount }}
                      </CNavLink>
                    </CNavItem>
                  </CNav>
                </CCardHeader>
                <CListGroup flush v-if="selectedPlanVersionList === 'planai' && planAIOffersCount > 0">
                  <CListGroupItem v-for="offer in planAIOffers" :key="offer.offerId">
                    <CTable>
                      <CTableBody>
                        <CTableRow>
                          <CTableHeaderCell>Name:</CTableHeaderCell>
                          <CTableDataCell>{{offer.offerName}}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell>SMS Sent:</CTableHeaderCell>
                          <CTableDataCell>{{offer.smsSentAt || 'Nil'}}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell>{{ offer.expired ? 'Expired' : 'Expires' }}:</CTableHeaderCell>
                          <CTableDataCell>{{offer.expiresAt || 'Nil'}}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell>Status:</CTableHeaderCell>
                          <CTableDataCell>{{offer.status}}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell style="border-bottom: none !important;">Eligibility:</CTableHeaderCell>
                          <CTableDataCell style="border-bottom: none !important;">{{offer.eligibilityCriteria || 'Nil'}}</CTableDataCell>
                        </CTableRow>
                      </CTableBody>
                    </CTable>
                  </CListGroupItem>
                </CListGroup>
                <CListGroup flush v-if="selectedPlanVersionList === 'planai' && planAIOffersCount <= 0">
                  <CListGroupItem>
                    <CCardTitle class="text-center">No offers found</CCardTitle>
                  </CListGroupItem>
                </CListGroup>
                <CListGroup flush v-if="selectedPlanVersionList === 'archived'">
                  <CListGroupItem v-for="(archivedPlanSubscription, index) in paginatedArchivedPlanSubscriptions" :key="archivedPlanSubscription.planSubscriptionId">
                    <b>Plan Name:&nbsp;</b>
                    <a :href="`https://plan.totogi.com/plans/${archivedPlanSubscription.planVersion.plan.id}?versionId=${archivedPlanSubscription.planVersion.id}`" target="_blank">
                      {{ archivedPlanSubscription.planVersion.plan.name }}
                    </a>
                    <br />
                    <b>Plan Version:&nbsp;</b> {{ archivedPlanSubscription.planVersion.version }}
                    <br />
                    <b>Plan Subscription ID:&nbsp;</b> <TotBadge :copyValue="archivedPlanSubscription.planSubscriptionId">{{archivedPlanSubscription.planSubscriptionIdText}}</TotBadge>
                    <br />
                    <b>Starting Date:&nbsp;</b> {{ archivedPlanSubscription.fromText }}
                    <br />
                    <b>Ending Date:&nbsp;</b> {{ archivedPlanSubscription.toText }}
                    <span v-if="archivedPlanSubscription.overrides.length > 0">
                      <br/>
                      <b class="tw-text-purple-600 text-primary" style="cursor: pointer;" @click="toggleOverrideView(archivedPlanSubscription)">
                        <font-awesome-icon icon="fa-solid fa-caret-right" v-if="!archivedPlanSubscription.overridesExpanded" />
                        <font-awesome-icon icon="fa-solid fa-caret-down" v-else />
                        Overrides
                      </b>
                      <br :sync="true" v-if="archivedPlanSubscription.overridesExpanded" />
                      <span :sync="true" v-if="archivedPlanSubscription.overridesExpanded">
                        <span v-for="override in archivedPlanSubscription.overrides" :key="override.name">
                          <b>{{ override.name }}:&nbsp;</b> {{ override.value }}
                          <br />
                        </span>
                      </span>
                    </span>
                    <!--<b>Services:</b>
                    <ul>
                      <li v-for="planService in archivedPlanSubscription.planVersion.planServices" :key="planService.id">
                        {{ planService.name }} - <b>RG {{ archivedPlanSubscription.ratingGroupId }}</b> - <b>Balances</b>:&nbsp;
                        <span v-if="planService.balanceTypeIds && planService.balanceTypeIds.length > 0">{{ (planService.balanceTypeIds || []).join(', ') }}</span>
                        <span v-else>None</span>
                      </li>
                    </ul>-->
                    <div v-if="archivedPlanSubscriptionsCount > 5 && index === paginatedArchivedPlanSubscriptions.length - 1" class="d-flex flex-row-reverse">
                      <CPagination size="sm">
                        <CPaginationItem style="cursor: pointer;" @click="setArchivedPlansSubscriptionPage(1)" title="First" :disabled="archivedPlanSubscriptionsPage === 1"><span aria-hidden="true">&laquo;</span></CPaginationItem>
                        <CPaginationItem style="cursor: pointer;" @click="setArchivedPlansSubscriptionPage(archivedPlanSubscriptionsPage - 2)" v-if="archivedPlanSubscriptionsPage === archivedPlanSubscriptionsPagesCount && archivedPlanSubscriptionsPagesCount > 2">{{ archivedPlanSubscriptionsPage - 2 }}</CPaginationItem>
                        <CPaginationItem style="cursor: pointer;" @click="setArchivedPlansSubscriptionPage(archivedPlanSubscriptionsPage - 1)" v-if="archivedPlanSubscriptionsPage > 1">{{ archivedPlanSubscriptionsPage - 1 }}</CPaginationItem>
                        <CPaginationItem style="cursor: pointer;" active>{{ archivedPlanSubscriptionsPage }}</CPaginationItem>
                        <CPaginationItem style="cursor: pointer;" @click="setArchivedPlansSubscriptionPage(archivedPlanSubscriptionsPage + 1)" v-if="archivedPlanSubscriptionsPage < archivedPlanSubscriptionsPagesCount">{{ archivedPlanSubscriptionsPage + 1 }}</CPaginationItem>
                        <CPaginationItem style="cursor: pointer;" @click="setArchivedPlansSubscriptionPage(archivedPlanSubscriptionsPage + 2)" v-if="archivedPlanSubscriptionsPage === 1 && archivedPlanSubscriptionsPagesCount > 2">{{ archivedPlanSubscriptionsPage + 2 }}</CPaginationItem>
                        <CPaginationItem style="cursor: pointer;" @click="setArchivedPlansSubscriptionPage(archivedPlanSubscriptionsPagesCount)" title="Last" :disabled="archivedPlanSubscriptionsPage === archivedPlanSubscriptionsPagesCount"><span aria-hidden="true">&raquo;</span></CPaginationItem>
                      </CPagination>
                    </div>
                  </CListGroupItem>
                </CListGroup>
                <CListGroup flush v-if="selectedPlanVersionList === 'active'">
                  <CListGroupItem v-for="activePlanSubscription in activePlanSubscriptions" :key="activePlanSubscription.planSubscriptionId">
                    <b>Plan Name:&nbsp;</b>
                    <a :href="`https://plan.totogidemos.com/plans/${activePlanSubscription.planVersion.plan.id}?versionId=${activePlanSubscription.planVersion.id}`" target="_blank">
                      {{ activePlanSubscription.planVersion.plan.name }}
                    </a>
                    <br />
                    <b>Plan Version:&nbsp;</b> {{ activePlanSubscription.planVersion.version }}
                    <br />
                    <b>Plan Subscription ID:&nbsp;</b> <TotBadge :copyValue="activePlanSubscription.planSubscriptionId">{{activePlanSubscription.planSubscriptionIdText}}</TotBadge>
                    <br />
                    <b>Starting Date:&nbsp;</b> {{ activePlanSubscription.fromText }}
                    <br />
                    <b>Ending Date:&nbsp;</b> {{ activePlanSubscription.toText }}
                    <span v-if="activePlanSubscription.overrides.length > 0">
                      <br/>
                      <b class="tw-text-purple-600 text-primary" style="cursor: pointer;" @click="toggleOverrideView(activePlanSubscription)">
                        <font-awesome-icon icon="fa-solid fa-caret-right" v-if="!activePlanSubscription.overridesExpanded" />
                        <font-awesome-icon icon="fa-solid fa-caret-down" v-else />
                        Overrides
                      </b>
                      <br :sync="true" v-if="activePlanSubscription.overridesExpanded" />
                      <span :sync="true" v-if="activePlanSubscription.overridesExpanded">
                        <span v-for="override in activePlanSubscription.overrides" :key="override.name">
                          <b>{{ override.name }}:&nbsp;</b> {{ override.value }}
                          <br />
                        </span>
                      </span>
                    </span>
                    <!--<b>Services:</b>
                    <ul>
                      <li v-for="planService in activePlanSubscription.planVersion.planServices" :key="planService.id">
                        {{ planService.name }} - <b>RG {{ planService.ratingGroupId }}</b> - <b>Balances</b>:&nbsp;
                        <span v-if="planService.balanceTypeIds && planService.balanceTypeIds.length > 0">{{ (planService.balanceTypeIds || []).join(', ') }}</span>
                        <span v-else>None</span>
                      </li>
                    </ul>-->
                  </CListGroupItem>
                </CListGroup>
              </CCard>
            </CCol>
            <CCol sm="7">
              <CCard class="detail-card" style="min-height: 100%;">
                <CCardHeader>
                  Account Balances
                </CCardHeader>
                <CTable>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>
                        Name
                      </CTableHeaderCell>
                      <CTableHeaderCell>
                        Used
                      </CTableHeaderCell>
                      <CTableHeaderCell>
                        Available
                      </CTableHeaderCell>
                      <!--<CTableHeaderCell>
                      </CTableHeaderCell>-->
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    <CTableRow v-for="(balance, idx) in balances" :key="idx">
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === balances.length - 1 }">
                        {{ balance.balanceType.name }}
                        &nbsp;
                        <TotBadge :copyValue="balance.balanceId">{{ balance.balanceId.substring(0, 8) }}</TotBadge>
                      </CTableDataCell>
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === balances.length - 1 }">
                        <span v-if="balance.balanceType.balanceTypeId === 'monetary'">{{currencySymbol}}</span>
                        <span v-if="balance.balanceType.balanceTypeId === 'monetary' || balance.balanceType.rateBased">{{ Math.round(balance.used * 100) / 100 }}</span>
                        <span v-else>{{ balance.readable_used }}</span>
                      </CTableDataCell>
                      <CTableDataCell :class="{ 'balances-table-last-row': idx === balances.length - 1 }">
                        <span v-if="balance.balanceType.balanceTypeId === 'monetary'">{{currencySymbol}}</span>
                        <span v-if="balance.balanceType.balanceTypeId === 'monetary' || balance.balanceType.rateBased">{{ Math.round(balance.available * 100) / 100 }}</span>
                        <span v-else>{{ balance.readable_available }}</span>
                      </CTableDataCell>
                      <!--<CTableDataCell :class="{ 'balances-table-last-row': idx === balances.length - 1 }">
                        <CButton
                          size="sm"
                          shape="rounded-pill"
                          color="primary"
                          variant="outline"
                          v-if="balance.balanceType.balanceTypeId === 'monetary'"
                          :disabled="updatingBalance || isDeviceLoading"
                          @click="initiateUpdateBalance(balance)">
                          Update
                        </CButton>
                      </CTableDataCell>-->
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="pt-4" v-if="deviceLoaded">
            <CCol sm="6">
              <h3>
                EDRs
              </h3>
            </CCol>
            <CCol sm="6" class="py-2 d-flex">
              <div class="px-2" style="vertical-align: middle; line-height: 38px;">
                <CLabel>
                  Filter by Time Period
                </CLabel>
              </div>
              <div class="flex-grow-1">
                <Datepicker
                  v-model="date"
                  :is24="false"
                  placeholder="Select Time Period"
                  :range="true"
                  :timezone="'+02:00'"
                  :format="'yyyy-MM-dd h:mm aaa'"
                  @update:model-value="handleDateChange($event)"
                />
              </div>
              <div class="ps-2">
                <CButton type="button" color="primary" @click="loadEDRs" :disabled="edrsLoading || isDeviceLoading">
                  {{ edrsLoading ? 'Loading...' : 'Load EDRs' }}
                </CButton>
              </div>
            </CCol>
          </CRow>
          <CRow class="pt-3" v-if="edrsLoaded">
            <CCol>
              <CCard class="detail-card">
                <CCardHeader>
                  <CNav variant="underline-border" class="edr-tab-headers">
                    <CNavItem>
                      <CNavLink :active="selectedEdrsTab === 'VOICE'" @click="handleEdrTabChange('VOICE')">
                        <font-awesome-icon icon="fa-solid fa-phone" />
                        &nbsp;VOICE
                      </CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink :active="selectedEdrsTab === 'TEXT'" @click="handleEdrTabChange('TEXT')">
                        <font-awesome-icon icon="fa-solid fa-message" />
                        &nbsp;TEXT
                      </CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink :active="selectedEdrsTab === 'DATA'" @click="handleEdrTabChange('DATA')">
                        <font-awesome-icon icon="fa-solid fa-signal" />
                        &nbsp;DATA
                      </CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink :active="selectedEdrsTab === 'TOPUPS'" @click="handleEdrTabChange('TOPUPS')">
                        <font-awesome-icon icon="fa-solid fa-receipt" />
                        &nbsp;TOPUPS
                      </CNavLink>
                    </CNavItem>
                  </CNav>
                </CCardHeader>
                <VoiceEdrs v-if="selectedEdrsTab === 'VOICE'" :voiceEdrs="voiceEDRs || []" />
                <TextEdrs v-if="selectedEdrsTab === 'TEXT'" :textEdrs="textEDRs || []" />
                <DataEdrs v-if="selectedEdrsTab === 'DATA'" :dataEdrs="dataEDRs || []" />
                <TopupEdrs v-if="selectedEdrsTab === 'TOPUPS'" :topupEdrs="topUpHistory || []" />
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <TotInputModal
        :error="entityInputModalError"
        :showModal="entityInputModalShowModal"
        :placeholder="entityInputModalPlaceholder"
        :helpText="entityInputModalHelpText"
        :processingAction="entityInputModalProcessing"
        @modal-closed="handleEntityInputModalClose"
        @modal-submitted="handleEntityInputModalSubmitted"
      >
        <template #title>{{ entityInputModalTitle }}</template>
        <template #modalProcessingText>{{ entityInputModalProcessingText }}</template>
        <template #modalSubmitText>{{ entityInputModalSubmitText }}</template>
      </TotInputModal>
    </CContainer>
  </div>
</template>

<script>
import { GRAPHQL_DEVICE_NOT_FOUND_ERROR_CODE, EDRS_CALLED_BY_MAPPING_ZAIN } from '../utils/constants'
import moment from 'moment-timezone'
import { getReadableEntityValue } from '@/store/modules/utils/unifiedBalances'
import { graphQLUrl } from '@/store/common'
import { axiosGraphQlRequest } from '@/utils/axiosInstances'
import { updateBalanceMutation } from '@/store/modules/utils/totogi/mutations'

import TotBadge from '@/components/common/tot-badge.vue'
import TotInputModal from '@/components/common/tot-input-modal'
import DataEdrs from '@/components/DataEdrs.vue'
import TextEdrs from '@/components/TextEdrs.vue'
import VoiceEdrs from '@/components/VoiceEdrs.vue'
import TopupEdrs from '@/components/TopupEdrs.vue'

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const SUBSCRIPTIONS_PAGINATION_LIMIT = 5

const rgTotalCalculation = (charge) => {
  let chargedAmount = charge.chargedtotal
  if (charge.chargedtax) {
    chargedAmount -= charge.chargedtax
  }
  if (charge.appliedrate) {
    chargedAmount /= charge.appliedrate
  }

  return chargedAmount
}

export default {
  name: 'DeviceDetails',
  components: { TotBadge, TotInputModal, Datepicker, DataEdrs, TextEdrs, VoiceEdrs, TopupEdrs },
  data () {
    return {
      deviceId: '',
      isDeviceLoading: false,
      isDeviceLoaded: false,
      deviceIdError: '',
      updatingBalance: false,
      entityInputModalTitle: '',
      entityInputModalShowModal: false,
      entityInputModalError: null,
      entityInputModalPlaceholder: '',
      entityInputModalHelpText: '',
      entityInputModalProcessing: false,
      entityInputModalProcessingText: '',
      entityInputModalSubmitText: '',
      entityInputModalType: '',
      balanceBeingUpdated: null,
      selectedPlanVersionList: 'active',
      selectedEdrsTab: 'VOICE',
      date: [
        moment().subtract(1, 'day').format(),
        moment().format()
      ],
      paginatedActivePlanSubscriptions: [],
      paginatedArchivedPlanSubscriptions: [],
      activePlanSubscriptionsPage: 1,
      archivedPlanSubscriptionsPage: 1
    }
  },
  computed: {
    planAIOffersCount () {
      return this.planAIOffers.length
    },
    planAIOffers () {
      return (this.$store.state.device.planAIOffers || []).map(planAIOffer => {
        const buildEligibilityCriteria = (offer) => {
          let criteria = ''
          switch (offer.offerType) {
            case 'TOPUP_BONUS':
              criteria = `Topup ${offer.minTopupAmount} SDG to receive ${offer.bonusQuantity}GB`
              break
            case 'BUNDLE_BONUS':
              criteria = `Purchase bundle ${offer.bundlePrice} SDG with ${offer.minTopupAmount}GB to receive ${offer.bonusQuantity}GB`
              break
            case 'BUNDLE_DISC':
              criteria = `Purchase bundle ${offer.bundlePrice}SDG (discounted amount) with ${offer.minTopupAmount}GB at discount rate ${offer.bonusQuantity * 100}%`
              break
          }
          return criteria
        }
        const buildStatusText = (offer) => {
          if (offer.offerAccepted) {
            return `Accepted - ${planAIOffer.completionTime ? moment(planAIOffer.completionTime).tz('Africa/Khartoum').format('YYYY-MM-DD hh:mm a') : ''}`
          } else if (offer.offerRejected) {
            return `Rejected - ${planAIOffer.completionTime ? moment(planAIOffer.completionTime).tz('Africa/Khartoum').format('YYYY-MM-DD hh:mm a') : ''}`
          } else if (planAIOffer.validUntil ? moment().isAfter(moment(planAIOffer.validUntil)) : false) {
            return `Expired - ${moment(planAIOffer.validUntil).tz('Africa/Khartoum').format('YYYY-MM-DD hh:mm a')}`
          } else {
            return 'Awaiting Response'
          }
        }
        return {
          offerId: planAIOffer.offerId,
          offerName: planAIOffer.offerName,
          offerType: planAIOffer.offerType,
          smsSentAt: planAIOffer.smsSentTime ? moment(planAIOffer.smsSentTime).tz('Africa/Khartoum').format('YYYY-MM-DD hh:mm a') : null,
          expired: planAIOffer.validUntil ? moment().isAfter(moment(planAIOffer.validUntil)) : false,
          expiresAt: planAIOffer.validUntil ? moment(planAIOffer.validUntil).tz('Africa/Khartoum').format('YYYY-MM-DD hh:mm a') : null,
          status: buildStatusText(planAIOffer),
          eligibilityCriteria: buildEligibilityCriteria(planAIOffer)
        }
      })
    },
    configLoading () {
      return !this.$store.state.dispatchedProviderConfig || !this.$store.state.configLoaded
    },
    accountIdText () {
      return this.$store.state.device.demoDevice.account?.id
    },
    balances () {
      return this.$store.state.device.demoDevice.account.balances.filter(balance => {
        console.log('Balance', moment(), moment(balance.to), moment().diff(moment(balance.to), 'day'))
        if (balance.to) {
          try {
            return (moment().diff(moment(balance.to), 'day') <= 0)
          } catch (except) {
            console.error('Error parsing Account balance "to" date', balance, balance.to)
            return true
          }
        } else {
          return true
        }
      }).map(balance => {
        /* const isUnlimitedBalance = this.$store.state.device.demoAccount.activePlanVersions.filter(activePlanSubscription => {
          return activePlanSubscription.planVersion.template.services.filter(templateService => (templateService.managedBalance?.managedBalanceTypeId === balance.balanceType.balanceTypeId && templateService.managedBalance?.periodAllowance === null)).length > 0
        }).length > 0 */
        const unlimitedText = null // isUnlimitedBalance ? 'Unlimited' : null
        const readableAvailable = unlimitedText || getReadableEntityValue(balance.balanceType.balanceTypeId, balance.available, balance.balanceType.unitType)
        return {
          ...balance,
          readable_available: readableAvailable,
          readable_total: getReadableEntityValue(balance.balanceType.balanceTypeId, balance.total, balance.balanceType.unitType),
          readable_used: getReadableEntityValue(balance.balanceType.balanceTypeId, balance.used, balance.balanceType.unitType),
          isUnlimitedBalance: null
        }
      })
    },
    deviceIdText () {
      return this.$store.state.device.demoDevice.id
    },
    deviceLoadingPercent () {
      return this.$store.state.device.loadingPercent
    },
    deviceLoadingStatus () {
      return this.$store.state.device.loadingStatus
    },
    deviceLoading () {
      return this.$store.state.device.loadingDevice
    },
    edrsLoaded () {
      return this.$store.state.device.edrsLoaded
    },
    edrsLoading () {
      return this.$store.state.device.edrsLoading
    },
    deviceLoaded () {
      return this.$store.state.device.deviceLoaded
    },
    activePlanSubscriptionsCount () {
      return (this.$store.state.device.demoDevice.account.activePlanVersions || []).length
    },
    activePlanSubscriptionsPagesCount () {
      return Math.ceil(this.activePlanSubscriptionsCount / SUBSCRIPTIONS_PAGINATION_LIMIT)
    },
    activePlanSubscriptions () {
      return (this.$store.state.device.demoDevice.account.activePlanVersions || []).map(activePlanSubscription => {
        const isToTimeFar = moment(activePlanSubscription.to).diff(moment(), 'days') > 50000
        return Object.assign(activePlanSubscription, {
          planSubscriptionIdText: activePlanSubscription.planSubscriptionId.slice(0, 8),
          fromText: moment(activePlanSubscription.from).tz('Africa/Khartoum').format('YYYY-MM-DD hh:mm a'),
          toText: (isToTimeFar ? 'No end time' : moment(activePlanSubscription.to).tz('Africa/Khartoum').format('YYYY-MM-DD hh:mm a'))
        })
      })
    },
    archivedPlanSubscriptionsCount () {
      return (this.$store.state.device.demoDevice.account.archivedPlanVersions || []).length
    },
    archivedPlanSubscriptionsPagesCount () {
      return Math.ceil(this.archivedPlanSubscriptionsCount / SUBSCRIPTIONS_PAGINATION_LIMIT)
    },
    archivedPlanSubscriptions () {
      return (this.$store.state.device.demoDevice.account.archivedPlanVersions || []).map(archivedPlanVersion => {
        const isToTimeFar = moment(archivedPlanVersion.to).diff(moment(), 'days') > 50000
        return Object.assign(archivedPlanVersion, {
          planSubscriptionIdText: archivedPlanVersion.planSubscriptionId.slice(0, 8),
          fromText: moment(archivedPlanVersion.from).tz('Africa/Khartoum').format('YYYY-MM-DD hh:mm a'),
          toText: (isToTimeFar ? 'No end time' : moment(archivedPlanVersion.to).tz('Africa/Khartoum').format('YYYY-MM-DD hh:mm a'))
        })
      })
    },
    currencySymbol () {
      return this.$store.state.settings.currencySymbol
    },
    voiceEDRs () {
      const balanceTypesUsed = []
      const balanceTypeEDRs = {}
      const billingEDRs = this.$store.state.device.billingEDRs
      const voiceEDRs = billingEDRs.filter(edr => {
        return (
          edr.action === 'charge' &&
          edr.customData.charginginformation.multipleunitinformation.filter(mui => (this.$store.state.voiceRatingGroupBucket.indexOf(mui.ratinggroup) > -1)).length > 0
        )
      })
      const homeMcc = this.$store.state.mcc
      voiceEDRs.forEach(voiceEDR => {
        console.log('Voice EDR', voiceEDR)
        const timeValue = moment(voiceEDR.createdAt).tz(this.$store.state.appTimezone)
        const timeString = timeValue.format('YYYY-MM-DD hh:mm a')
        const imsChargingInformation = voiceEDR.customData.charginginformation.imscharginginformation || {}
        const edrMcc = voiceEDR.customData.charginginformation.locationreportingcharginginformation?.pscellinformation?.nrcgi?.plmnid?.mcc
        const isRoaming = homeMcc !== edrMcc
        console.log('Roaming', homeMcc, edrMcc, isRoaming)
        // const fromParty = (imsChargingInformation.callingpartyaddresses && imsChargingInformation.callingpartyaddresses[0]) ? imsChargingInformation.callingpartyaddresses[0] : ((imsChargingInformation.roleofnode === 'ORIGINATING' || imsChargingInformation.roleofimsnode === 'ORIGINATING') ? this.$store.state.device.demoDevice.id : (imsChargingInformation.calledpartyaddress || ''))
        // const toParty = (imsChargingInformation.callingpartyaddresses && imsChargingInformation.callingpartyaddresses[0]) ? imsChargingInformation.calledpartyaddress : ((imsChargingInformation.roleofnode === 'ORIGINATING' || imsChargingInformation.roleofimsnode === 'ORIGINATING') ? (imsChargingInformation.calledpartyaddress || '') : this.$store.state.device.demoDevice.id)
        const fromParty = ((imsChargingInformation.callingpartyaddresses && imsChargingInformation.callingpartyaddresses[0]) ? imsChargingInformation.callingpartyaddresses[0] : (voiceEDR.customData.pdusessioncharginginformation?.userinformation?.servedgpsi || this.$store.state.device.demoDevice.id))
        const toParty = imsChargingInformation.calledpartyaddress || voiceEDR.customData.pdusessioncharginginformation?.userinformation?.servedgpsi || this.$store.state.device.demoDevice.id
        const multipleunitinformation = voiceEDR.customData.charginginformation.multipleunitinformation || []
        const edrLevelBalanceTypes = []
        const edrLevelBalanceTypeData = {}
        console.log('time, calling and called parties', timeString, fromParty, toParty)
        multipleunitinformation.forEach(mui => {
          const totalTime = mui.grantedunit?.time || 0
          const ratingGroup = mui.ratinggroup
          const muiLevelBalanceTypes = []
          const muiLevelBalanceTypeData = {}
          mui.charges.forEach(charge => {
            const balanceTypeId = charge.balanceused
            const tax = (Math.round(charge.chargedtax * 100) / 100)
            const total = (Math.round(charge.chargedtotal * 100) / 100)
            const base = (Math.round((total - tax) * 100) / 100)
            const rgTotal = rgTotalCalculation(charge)
            if (muiLevelBalanceTypeData[balanceTypeId]) {
              muiLevelBalanceTypeData[balanceTypeId].tax += tax
              muiLevelBalanceTypeData[balanceTypeId].total += total
              muiLevelBalanceTypeData[balanceTypeId].rgTotal += rgTotal
              muiLevelBalanceTypeData[balanceTypeId].base += base
            } else {
              muiLevelBalanceTypes.push(balanceTypeId)
              muiLevelBalanceTypeData[balanceTypeId] = {
                tax,
                total,
                rgTotal,
                base
              }
            }
          })
          for (const muiLevelBalanceTypeId of muiLevelBalanceTypes) {
            if (edrLevelBalanceTypeData[muiLevelBalanceTypeId]) {
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].tax += muiLevelBalanceTypeData[muiLevelBalanceTypeId].tax
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].total += muiLevelBalanceTypeData[muiLevelBalanceTypeId].total
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].rgTotal += muiLevelBalanceTypeData[muiLevelBalanceTypeId].rgTotal
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].base += muiLevelBalanceTypeData[muiLevelBalanceTypeId].base
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].totalTime += totalTime
            } else {
              edrLevelBalanceTypes.push(muiLevelBalanceTypeId)
              edrLevelBalanceTypeData[muiLevelBalanceTypeId] = {
                tax: muiLevelBalanceTypeData[muiLevelBalanceTypeId].tax,
                total: muiLevelBalanceTypeData[muiLevelBalanceTypeId].total,
                rgTotal: muiLevelBalanceTypeData[muiLevelBalanceTypeId].rgTotal,
                base: muiLevelBalanceTypeData[muiLevelBalanceTypeId].base,
                totalTime,
                ratingGroup
              }
            }
          }
        })

        // zero charge edr balances
        /* const balances = voiceEDR.customData.balances || []
        for (const balance of balances) {
          const balanceTypeId = balance.balancetypeid
          const usedBalanceValue = balance.after.used || 0
          if (!usedBalanceValue) {
            continue
          }

          if (!edrLevelBalanceTypeData[balanceTypeId]) {
            edrLevelBalanceTypes.push(balanceTypeId)
            edrLevelBalanceTypeData[balanceTypeId] = {
              tax: 0,
              total: balance.after.used,
              rgTotal: 0,
              base: 0,
              totalVolume: 0,
              ratingGroup: 0,
              timeString,
              timeValue,
              newBalanceValue: 0,
              isRoaming
            }
          }
        } */
        for (const edrLevelBalanceTypeId of edrLevelBalanceTypes) {
          let newBalanceValue = 0
          let newBalanceData = (Array.isArray(voiceEDR.customData.balances) ? voiceEDR.customData.balances.filter(balance => balance.balancetypeid === edrLevelBalanceTypeId) : voiceEDR.customData.balances[edrLevelBalanceTypeId])
          newBalanceData = newBalanceData.map(balance => (balance.after?.available || 0))
          if (!newBalanceData.length) {
            console.log('Error in new balance calculation - Voice', voiceEDR.customData.balances, edrLevelBalanceTypeId, newBalanceData)
          } else {
            newBalanceValue = newBalanceData.reduce((sum, availableBalanceValue) => (sum || 0) + (isNaN(availableBalanceValue) ? 0 : Number(availableBalanceValue)))
          }
          console.log('Is Roaming2', isRoaming)
          if (balanceTypeEDRs[edrLevelBalanceTypeId]) {
            balanceTypeEDRs[edrLevelBalanceTypeId].push({
              tax: edrLevelBalanceTypeData[edrLevelBalanceTypeId].tax,
              total: edrLevelBalanceTypeData[edrLevelBalanceTypeId].total,
              rgTotal: edrLevelBalanceTypeData[edrLevelBalanceTypeId].rgTotal,
              base: edrLevelBalanceTypeData[edrLevelBalanceTypeId].base,
              totalTime: edrLevelBalanceTypeData[edrLevelBalanceTypeId].totalTime,
              ratingGroup: edrLevelBalanceTypeData[edrLevelBalanceTypeId].ratingGroup,
              timeString,
              timeValue,
              fromParty,
              toParty,
              newBalanceValue,
              isRoaming
            })
          } else {
            console.log('Voice EDR balances', voiceEDR.customData.balances[edrLevelBalanceTypeId], voiceEDR.customData.balances, edrLevelBalanceTypeId)
            balanceTypesUsed.push(edrLevelBalanceTypeId)
            balanceTypeEDRs[edrLevelBalanceTypeId] = [{
              tax: edrLevelBalanceTypeData[edrLevelBalanceTypeId].tax,
              total: edrLevelBalanceTypeData[edrLevelBalanceTypeId].total,
              rgTotal: edrLevelBalanceTypeData[edrLevelBalanceTypeId].rgTotal,
              base: edrLevelBalanceTypeData[edrLevelBalanceTypeId].base,
              totalTime: edrLevelBalanceTypeData[edrLevelBalanceTypeId].totalTime,
              ratingGroup: edrLevelBalanceTypeData[edrLevelBalanceTypeId].ratingGroup,
              timeString,
              timeValue,
              fromParty,
              toParty,
              newBalanceValue,
              isRoaming
            }]
          }
        }
      })
      const tableData = []
      const tableData2 = []
      voiceEDRs.forEach(voiceEdr => {
        (voiceEdr?.customData?.charginginformation?.multipleunitinformation || []).forEach(mui => {
          (mui.charges || []).forEach(charge => {
            tableData2.push(charge)
          })
          tableData.push(mui)
        })
      })
      console.log('VoiceEDR Charges')
      console.table(tableData)
      console.table(tableData2)
      console.log(tableData)
      console.log(tableData2)
      return balanceTypesUsed.map(balanceTypeId => {
        console.log('Balance Type', balanceTypeId, this.$store.state.balanceTypes)
        const balanceType = this.$store.state.balanceTypes.find(balanceType => balanceType.balanceTypeId === balanceTypeId)
        const isMonetary = balanceType.balanceTypeId === 'monetary' || balanceType.rateBased
        const edrs = balanceTypeEDRs[balanceTypeId].map(edr => {
          console.log('EDR', edr.isRoaming)
          return {
            totalTime: `${Math.floor(edr.totalTime / 60)} mins ${String(edr.totalTime % 60).padStart(2, '0')} secs`,
            rgTotalTime: `${Math.floor(edr.rgTotal / 60)} mins ${String(Math.round(edr.rgTotal % 60)).padStart(2, '0')} secs`,
            time: edr.timeString,
            timeValue: edr.timeValue,
            ratingGroup: edr.ratingGroup,
            fromParty: edr.fromParty,
            toParty: edr.toParty,
            base: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.base * 100) / 100).toFixed(2)}` : ''),
            tax: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.tax * 100) / 100).toFixed(2)}` : ''),
            total: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.total * 100) / 100).toFixed(2)}` : `${Math.floor(edr.total / 60)} mins ${String(edr.total % 60).padStart(2, '0')} secs`),
            newBalanceValue: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.newBalanceValue * 100) / 100).toFixed(2)}` : `${Math.floor(edr.newBalanceValue / 60)} mins ${String(edr.newBalanceValue % 60).padStart(2, '0')} secs`),
            isRoaming: edr.isRoaming
          }
        })
        edrs.sort((a, b) => {
          return (a.timeValue.isAfter(b.timeValue) ? -1 : 1)
        })
        return {
          name: balanceType.name,
          isMonetary,
          edrs
        }
      })
    },
    textEDRs () {
      const balanceTypesUsed = []
      const balanceTypeEDRs = {}
      const billingEDRs = this.$store.state.device.billingEDRs
      const textEDRs = billingEDRs.filter(edr => {
        return (
          edr.action === 'charge' &&
          edr.customData.charginginformation.multipleunitinformation.filter(mui => (this.$store.state.textRatingGroupBucket.indexOf(mui.ratinggroup) > -1)).length > 0
        )
      })
      const homeMcc = this.$store.state.mcc
      textEDRs.forEach(textEDR => {
        console.log('Text EDR', textEDR)
        const timeValue = moment(textEDR.createdAt).tz(this.$store.state.appTimezone)
        const timeString = timeValue.format('YYYY-MM-DD hh:mm a')
        const imsChargingInformation = textEDR.customData.charginginformation.imscharginginformation || {}
        const edrMcc = textEDR.customData.charginginformation.locationreportingcharginginformation?.pscellinformation?.nrcgi?.plmnid?.mcc
        const isRoaming = homeMcc !== edrMcc
        // const fromParty = (imsChargingInformation.callingpartyaddresses && imsChargingInformation.callingpartyaddresses[0]) ? imsChargingInformation.callingpartyaddresses[0] : ((imsChargingInformation.roleofnode === 'ORIGINATING' || imsChargingInformation.roleofimsnode === 'ORIGINATING') ? this.$store.state.device.demoDevice.id : (imsChargingInformation.calledpartyaddress || ''))
        // const toParty = (imsChargingInformation.callingpartyaddresses && imsChargingInformation.callingpartyaddresses[0]) ? imsChargingInformation.calledpartyaddress : ((imsChargingInformation.roleofnode === 'ORIGINATING' || imsChargingInformation.roleofimsnode === 'ORIGINATING') ? (imsChargingInformation.calledpartyaddress || '') : this.$store.state.device.demoDevice.id)
        const fromParty = ((imsChargingInformation.callingpartyaddresses && imsChargingInformation.callingpartyaddresses[0]) ? imsChargingInformation.callingpartyaddresses[0] : (textEDR.customData.pdusessioncharginginformation?.userinformation?.servedgpsi || this.$store.state.device.demoDevice.id))
        const toParty = imsChargingInformation.calledpartyaddress || textEDR.customData.pdusessioncharginginformation?.userinformation?.servedgpsi || this.$store.state.device.demoDevice.id
        const multipleunitinformation = textEDR.customData.charginginformation.multipleunitinformation || []
        const edrLevelBalanceTypes = []
        const edrLevelBalanceTypeData = {}
        multipleunitinformation.forEach(mui => {
          const textsUsed = mui.grantedunit?.servicespecificunits || 0
          const ratingGroup = mui.ratinggroup
          const muiLevelBalanceTypes = []
          const muiLevelBalanceTypeData = {}
          mui.charges.forEach(charge => {
            const balanceTypeId = charge.balanceused
            const tax = (Math.round(charge.chargedtax * 100) / 100)
            const total = (Math.round(charge.chargedtotal * 100) / 100)
            const base = (Math.round((total - tax) * 100) / 100)
            const rgTotal = rgTotalCalculation(charge)
            if (muiLevelBalanceTypeData[balanceTypeId]) {
              muiLevelBalanceTypeData[balanceTypeId].tax += tax
              muiLevelBalanceTypeData[balanceTypeId].total += total
              muiLevelBalanceTypeData[balanceTypeId].rgTotal += rgTotal
              muiLevelBalanceTypeData[balanceTypeId].base += base
            } else {
              muiLevelBalanceTypes.push(balanceTypeId)
              muiLevelBalanceTypeData[balanceTypeId] = {
                tax,
                total,
                rgTotal,
                base
              }
            }
          })
          for (const muiLevelBalanceTypeId of muiLevelBalanceTypes) {
            if (edrLevelBalanceTypeData[muiLevelBalanceTypeId]) {
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].tax += muiLevelBalanceTypeData[muiLevelBalanceTypeId].tax
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].total += muiLevelBalanceTypeData[muiLevelBalanceTypeId].total
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].rgTotal += muiLevelBalanceTypeData[muiLevelBalanceTypeId].rgTotal
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].base += muiLevelBalanceTypeData[muiLevelBalanceTypeId].base
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].textsUsed += textsUsed
            } else {
              edrLevelBalanceTypes.push(muiLevelBalanceTypeId)
              edrLevelBalanceTypeData[muiLevelBalanceTypeId] = {
                tax: muiLevelBalanceTypeData[muiLevelBalanceTypeId].tax,
                total: muiLevelBalanceTypeData[muiLevelBalanceTypeId].total,
                rgTotal: muiLevelBalanceTypeData[muiLevelBalanceTypeId].rgTotal,
                base: muiLevelBalanceTypeData[muiLevelBalanceTypeId].base,
                textsUsed,
                ratingGroup
              }
            }
          }
        })
        for (const edrLevelBalanceTypeId of edrLevelBalanceTypes) {
          let newBalanceValue = 0
          let newBalanceData = (Array.isArray(textEDR.customData.balances) ? textEDR.customData.balances.filter(balance => balance.balancetypeid === edrLevelBalanceTypeId) : textEDR.customData.balances[edrLevelBalanceTypeId])
          newBalanceData = newBalanceData.map(balance => (balance.after?.available || 0))
          if (!newBalanceData.length) {
            console.log('Error in new balance calculation - Voice', textEDR.customData.balances, edrLevelBalanceTypeId, newBalanceData)
          } else {
            newBalanceValue = newBalanceData.reduce((sum, availableBalanceValue) => (sum || 0) + (isNaN(availableBalanceValue) ? 0 : Number(availableBalanceValue)))
          }
          if (balanceTypeEDRs[edrLevelBalanceTypeId]) {
            balanceTypeEDRs[edrLevelBalanceTypeId].push({
              tax: edrLevelBalanceTypeData[edrLevelBalanceTypeId].tax,
              total: edrLevelBalanceTypeData[edrLevelBalanceTypeId].total,
              rgTotal: edrLevelBalanceTypeData[edrLevelBalanceTypeId].rgTotal,
              base: edrLevelBalanceTypeData[edrLevelBalanceTypeId].base,
              textsUsed: edrLevelBalanceTypeData[edrLevelBalanceTypeId].textsUsed,
              ratingGroup: edrLevelBalanceTypeData[edrLevelBalanceTypeId].ratingGroup,
              timeString,
              timeValue,
              fromParty,
              toParty,
              newBalanceValue,
              isRoaming
            })
          } else {
            console.log('Text EDR balances', textEDR.customData.balances[edrLevelBalanceTypeId], textEDR.customData.balances, edrLevelBalanceTypeId)
            balanceTypesUsed.push(edrLevelBalanceTypeId)
            balanceTypeEDRs[edrLevelBalanceTypeId] = [{
              tax: edrLevelBalanceTypeData[edrLevelBalanceTypeId].tax,
              total: edrLevelBalanceTypeData[edrLevelBalanceTypeId].total,
              rgTotal: edrLevelBalanceTypeData[edrLevelBalanceTypeId].rgTotal,
              base: edrLevelBalanceTypeData[edrLevelBalanceTypeId].base,
              textsUsed: edrLevelBalanceTypeData[edrLevelBalanceTypeId].textsUsed,
              ratingGroup: edrLevelBalanceTypeData[edrLevelBalanceTypeId].ratingGroup,
              timeString,
              timeValue,
              fromParty,
              toParty,
              newBalanceValue,
              isRoaming
            }]
          }
        }
      })
      return balanceTypesUsed.map(balanceTypeId => {
        console.log('Balance Type', balanceTypeId, this.$store.state.balanceTypes)
        const balanceType = this.$store.state.balanceTypes.find(balanceType => balanceType.balanceTypeId === balanceTypeId)
        const isMonetary = balanceType.balanceTypeId === 'monetary' || balanceType.rateBased
        const edrs = balanceTypeEDRs[balanceTypeId].map(edr => {
          return {
            textsUsed: `${edr.textsUsed}`,
            rgTextsUsed: `${Math.round(edr.rgTotal)}`,
            time: edr.timeString,
            timeValue: edr.timeValue,
            ratingGroup: edr.ratingGroup,
            fromParty: edr.fromParty,
            toParty: edr.toParty,
            base: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.base * 100) / 100).toFixed(2)}` : ''),
            tax: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.tax * 100) / 100).toFixed(2)}` : ''),
            total: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.total * 1000) / 1000).toFixed(2)}` : `${Math.floor(edr.total / 60)} mins ${String(edr.total % 60).padStart(2, '0')} secs`),
            newBalanceValue: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.newBalanceValue * 100) / 100).toFixed(2)}` : `${Math.floor(edr.newBalanceValue)} SMS`),
            isRoaming: edr.isRoaming
          }
        })
        edrs.sort((a, b) => {
          return (a.timeValue.isAfter(b.timeValue) ? -1 : 1)
        })
        return {
          name: balanceType.name,
          isMonetary,
          edrs
        }
      })
    },
    dataEDRs () {
      const balanceTypesUsed = []
      const balanceTypeEDRs = {}
      const billingEDRs = this.$store.state.device.billingEDRs
      const dataEDRs = billingEDRs.filter(edr => {
        return (
          edr.action === 'charge' &&
          edr.customData.charginginformation.multipleunitinformation.filter(mui => (this.$store.state.dataRatingGroupBucket.indexOf(mui.ratinggroup) > -1)).length > 0
        )
      })
      const homeMcc = this.$store.state.mcc
      dataEDRs.forEach(dataEDR => {
        console.log('Data EDR', dataEDR)
        const timeValue = moment(dataEDR.createdAt).tz(this.$store.state.appTimezone)
        const timeString = timeValue.format('YYYY-MM-DD hh:mm a')
        const multipleunitinformation = dataEDR.customData.charginginformation.multipleunitinformation || []
        const edrMcc = dataEDR.customData.charginginformation.locationreportingcharginginformation?.pscellinformation?.nrcgi?.plmnid?.mcc
        const isRoaming = homeMcc !== edrMcc
        const edrLevelBalanceTypes = []
        const edrLevelBalanceTypeData = {}
        multipleunitinformation.forEach(mui => {
          const totalVolume = mui.grantedunit?.totalvolume || 0
          const ratingGroup = mui.ratinggroup
          const muiLevelBalanceTypes = []
          const muiLevelBalanceTypeData = {}
          mui.charges.forEach(charge => {
            const balanceTypeId = charge.balanceused
            const tax = (Math.round(charge.chargedtax * 100) / 100)
            const total = (Math.round(charge.chargedtotal * 100) / 100)
            const base = (Math.round((total - tax) * 100) / 100)
            const rgTotal = rgTotalCalculation(charge)
            if (muiLevelBalanceTypeData[balanceTypeId]) {
              muiLevelBalanceTypeData[balanceTypeId].tax += tax
              muiLevelBalanceTypeData[balanceTypeId].total += total
              muiLevelBalanceTypeData[balanceTypeId].rgTotal += rgTotal
              muiLevelBalanceTypeData[balanceTypeId].base += base
            } else {
              muiLevelBalanceTypes.push(balanceTypeId)
              muiLevelBalanceTypeData[balanceTypeId] = {
                tax,
                total,
                rgTotal: rgTotal,
                base
              }
            }
          })
          for (const muiLevelBalanceTypeId of muiLevelBalanceTypes) {
            if (edrLevelBalanceTypeData[muiLevelBalanceTypeId]) {
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].tax += muiLevelBalanceTypeData[muiLevelBalanceTypeId].tax
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].total += muiLevelBalanceTypeData[muiLevelBalanceTypeId].total
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].rgTotal += muiLevelBalanceTypeData[muiLevelBalanceTypeId].rgTotal
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].base += muiLevelBalanceTypeData[muiLevelBalanceTypeId].base
              edrLevelBalanceTypeData[muiLevelBalanceTypeId].totalVolume += totalVolume
            } else {
              edrLevelBalanceTypes.push(muiLevelBalanceTypeId)
              edrLevelBalanceTypeData[muiLevelBalanceTypeId] = {
                tax: muiLevelBalanceTypeData[muiLevelBalanceTypeId].tax,
                total: muiLevelBalanceTypeData[muiLevelBalanceTypeId].total,
                rgTotal: muiLevelBalanceTypeData[muiLevelBalanceTypeId].rgTotal,
                base: muiLevelBalanceTypeData[muiLevelBalanceTypeId].base,
                totalVolume,
                ratingGroup
              }
            }
          }
        })

        for (const edrLevelBalanceTypeId of edrLevelBalanceTypes) {
          let newBalanceValue = 0
          let newBalanceData = (Array.isArray(dataEDR.customData.balances) ? dataEDR.customData.balances.filter(balance => balance.balancetypeid === edrLevelBalanceTypeId) : dataEDR.customData.balances[edrLevelBalanceTypeId])
          newBalanceData = newBalanceData.map(balance => (balance.after?.available || 0))
          if (!newBalanceData.length) {
            console.log('Error in new balance calculation - Data', dataEDR.customData.balances, edrLevelBalanceTypeId, newBalanceData)
          } else {
            newBalanceValue = newBalanceData.reduce((sum, availableBalanceValue) => (sum || 0) + (isNaN(availableBalanceValue) ? 0 : Number(availableBalanceValue)))
          }
          if (balanceTypeEDRs[edrLevelBalanceTypeId]) {
            balanceTypeEDRs[edrLevelBalanceTypeId].push({
              tax: edrLevelBalanceTypeData[edrLevelBalanceTypeId].tax,
              total: edrLevelBalanceTypeData[edrLevelBalanceTypeId].total,
              rgTotal: edrLevelBalanceTypeData[edrLevelBalanceTypeId].rgTotal,
              base: edrLevelBalanceTypeData[edrLevelBalanceTypeId].base,
              totalVolume: edrLevelBalanceTypeData[edrLevelBalanceTypeId].totalVolume,
              ratingGroup: edrLevelBalanceTypeData[edrLevelBalanceTypeId].ratingGroup,
              timeString,
              timeValue,
              newBalanceValue,
              isRoaming
            })
          } else {
            balanceTypesUsed.push(edrLevelBalanceTypeId)
            balanceTypeEDRs[edrLevelBalanceTypeId] = [{
              tax: edrLevelBalanceTypeData[edrLevelBalanceTypeId].tax,
              total: edrLevelBalanceTypeData[edrLevelBalanceTypeId].total,
              rgTotal: edrLevelBalanceTypeData[edrLevelBalanceTypeId].rgTotal,
              base: edrLevelBalanceTypeData[edrLevelBalanceTypeId].base,
              totalVolume: edrLevelBalanceTypeData[edrLevelBalanceTypeId].totalVolume,
              ratingGroup: edrLevelBalanceTypeData[edrLevelBalanceTypeId].ratingGroup,
              timeString,
              timeValue,
              newBalanceValue,
              isRoaming
            }]
          }
        }
      })
      return balanceTypesUsed.map(balanceTypeId => {
        const balanceType = this.$store.state.balanceTypes.find(balanceType => balanceType.balanceTypeId === balanceTypeId)
        const isMonetary = balanceType.balanceTypeId === 'monetary' || balanceType.rateBased
        const edrs = balanceTypeEDRs[balanceTypeId].map(edr => {
          return {
            volume: `${Math.round((edr.totalVolume / (1024 * 1024)) * 100) / 100} MB`,
            rgTotalVolume: `${Math.round((edr.rgTotal / (1024 * 1024)) * 100) / 100} MB`,
            time: edr.timeString,
            timeValue: edr.timeValue,
            ratingGroup: edr.ratingGroup,
            base: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.base * 100) / 100).toFixed(2)}` : ''),
            tax: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.tax * 100) / 100).toFixed(2)}` : ''),
            total: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.total * 100) / 100).toFixed(2)}` : `${Math.round((edr.totalVolume / (1024 * 1024)) * 100) / 100} MB`),
            newBalanceValue: (isMonetary ? `${balanceType.balanceTypeId === 'monetary' ? `${this.currencySymbol} ` : ''}${(Math.round(edr.newBalanceValue * 100) / 100).toFixed(2)}` : `${Math.round((edr.newBalanceValue / (1024 * 1024)) * 100) / 100} MB`),
            isRoaming: edr.isRoaming
          }
        })
        edrs.sort((a, b) => {
          return (a.timeValue.isAfter(b.timeValue) ? -1 : 1)
        })
        return {
          name: balanceType.name,
          isMonetary,
          edrs
        }
      })
    },
    topUpHistory () {
      const accountEDRs = this.$store.state.device.accountEDRs
      const updateBalanceEDRs = accountEDRs.filter(edr => {
        return (
          edr.action === 'updateBalance'
        )
      })
      return updateBalanceEDRs.map(updateBalanceEDR => {
        const balanceType = this.balances.find(balance => balance.balanceType.balanceTypeId === updateBalanceEDR.customData.balancetypeid)
        const unitType = balanceType?.balanceType?.unitType
        updateBalanceEDR.customData.balancebefore = isNaN(updateBalanceEDR.customData.balancebefore) ? 0 : Number(updateBalanceEDR.customData.balancebefore)
        updateBalanceEDR.customData.balanceafter = isNaN(updateBalanceEDR.customData.balanceafter) ? 0 : Number(updateBalanceEDR.customData.balanceafter)
        const amount = (updateBalanceEDR.customData.adjustment === 'SET' ? (updateBalanceEDR.customData.balanceafter || 0) : ((updateBalanceEDR.customData.balanceafter || 0) - (updateBalanceEDR.customData.balancebefore || 0)))
        const calledBy = EDRS_CALLED_BY_MAPPING_ZAIN[updateBalanceEDR.customData.calledby] || updateBalanceEDR.customData.calledby || ''
        return {
          time: moment(updateBalanceEDR.createdAt).tz(this.$store.state.appTimezone).format('YYYY-MM-DD hh:mm a'),
          calledBy,
          calledByOriginal: (updateBalanceEDR.customData.calledby || ''),
          adjustmentType: updateBalanceEDR.customData.adjustment,
          amount: `${updateBalanceEDR.customData.balancetypeid === 'monetary' ? `${this.currencySymbol} ` : ''}${getReadableEntityValue(updateBalanceEDR.customData.balancetypeid, amount, unitType)}`,
          balanceBefore: `${updateBalanceEDR.customData.balancetypeid === 'monetary' ? `${this.currencySymbol} ` : ''}${getReadableEntityValue(updateBalanceEDR.customData.balancetypeid, updateBalanceEDR.customData.balancebefore || 0, unitType)}`,
          balanceAfter: `${updateBalanceEDR.customData.balancetypeid === 'monetary' ? `${this.currencySymbol} ` : ''}${getReadableEntityValue(updateBalanceEDR.customData.balancetypeid, updateBalanceEDR.customData.balanceafter || 0, unitType)}`
        }
      })
    }
  },
  methods: {
    setActivePlansSubscriptionPage (page) {
      this.activePlanSubscriptionsPage = page
      this.paginatedActivePlanSubscriptions = this.activePlanSubscriptions.slice((page - 1) * SUBSCRIPTIONS_PAGINATION_LIMIT, page * SUBSCRIPTIONS_PAGINATION_LIMIT)
    },
    setArchivedPlansSubscriptionPage (page) {
      this.archivedPlanSubscriptionsPage = page
      this.paginatedArchivedPlanSubscriptions = this.archivedPlanSubscriptions.slice((page - 1) * SUBSCRIPTIONS_PAGINATION_LIMIT, page * SUBSCRIPTIONS_PAGINATION_LIMIT)
    },
    handleDateChange (event) {
      this.deviceId = this.deviceIdText
      this.loadEDRs()
    },
    async loadDevice () {
      if (!this.deviceId) {
        this.deviceIdError = 'Please enter the device ID'
        return
      }
      this.isDeviceLoading = true
      this.deviceIdError = ''
      try {
        await this.$store.dispatch('device/getDemoDevice', { deviceId: String(this.deviceId).trim(), fromTimestamp: moment(this.date[0] || moment().subtract(1, 'day')).toISOString(), toTimestamp: moment(this.date[1] || moment()).toISOString() })
      } catch (err) {
        if (err.errorCode && err.errorCode === GRAPHQL_DEVICE_NOT_FOUND_ERROR_CODE) {
          this.deviceIdError = 'Device not found, please check the ID'
          this.isDeviceLoading = false
          return
        }
      }
      await this.$nextTick()
      await this.$nextTick()
      this.isDeviceLoading = false
      this.deviceIdError = false
      this.deviceId = ''
      this.selectedPlanVersionList = 'active'
      this.setActivePlansSubscriptionPage(1)
      this.setArchivedPlansSubscriptionPage(1)
      this.isDeviceLoaded = true
      this.$store.dispatch('device/getPlanAIOffers', { deviceId: this.$store.state.device.demoDevice.id })
    },
    async loadEDRs () {
      if (!this.deviceLoaded) {
        this.deviceIdError = 'Please load the device first'
        return
      }
      try {
        await this.$store.dispatch('device/loadEDRs', { fromTimestamp: moment(this.date[0] || moment().subtract(1, 'day')).toISOString(), toTimestamp: moment(this.date[1] || moment()).toISOString() })
      } catch (err) {
      }
    },
    async handleEntityInputModalSubmitted (submittedValue) {
      switch (this.entityInputModalType) {
        case 'UpdateBalance':
          await this.updateBalance(submittedValue)
      }
    },
    handleEdrTabChange (tabName) {
      this.selectedEdrsTab = tabName
    },
    handleEntityInputModalClose () {
      this.entityInputModalTitle = ''
      this.entityInputModalShowModal = false
      this.entityInputModalError = null
      this.entityInputModalPlaceholder = ''
      this.entityInputModalProcessing = false
      this.entityInputModalProcessingText = ''
      this.entityInputModalSubmitText = ''
      this.entityInputModalType = ''
    },
    toggleOverrideView (activePlanSubscription) {
      activePlanSubscription.overridesExpanded = !activePlanSubscription.overridesExpanded
    },
    initiateUpdateBalance (balance) {
      this.entityInputModalTitle = 'Update balance'
      this.entityInputModalShowModal = true
      this.entityInputModalError = null
      this.entityInputModalPlaceholder = 'New balance value'
      this.entityInputModalHelpText = 'Enter a balance value'
      this.entityInputModalProcessing = false
      this.entityInputModalProcessingText = 'Updating balance'
      this.entityInputModalSubmitText = 'Update balance'
      this.entityInputModalType = 'UpdateBalance'
      this.balanceBeingUpdated = balance
    },
    async updateBalance (newBalanceValue) {
      const balanceId = this.balanceBeingUpdated.balanceId
      const balanceTypeId = this.balanceBeingUpdated.balanceType.balanceTypeId
      if (!newBalanceValue || isNaN(newBalanceValue)) {
        this.entityInputModalError = 'Please enter a valid amount'
        return
      }
      this.updatingBalance = true
      this.entityInputModalProcessing = true
      await axiosGraphQlRequest(
        graphQLUrl,
        updateBalanceMutation,
        {
          input: {
            balanceId,
            providerId: this.$store.state.providerId,
            accountId: this.$store.state.device.demoDevice.account.id,
            balanceInfo: {
              balanceTypeId,
              balanceValue: newBalanceValue
            }
          }
        },
        this.$store.getters.graphQLHeaders
      )
      this.entityInputModalProcessing = false
      this.updatingBalance = false
      this.entityInputModalShowModal = false
      this.isDeviceLoading = true
      this.balanceBeingUpdated = null
      await this.$store.dispatch('device/getDemoDevice', { deviceId: this.$store.state.device.demoDevice.id })
      this.isDeviceLoading = false
    }
  }
}
</script>

<style>
.balances-table-last-row {
  border-bottom:  none !important;
}
.detail-card {
  border-top: 4px solid rgb(128, 45, 200) !important;
  border-bottom: none !important;
  border-right: none !important;
  border-left: none !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}
.detail-card .card-header {
  border: none !important;
  background: none !important;
  font-size: 20px !important;
  color: rgb(128, 45, 200) !important;
}
.edr-tab-headers {
  border-bottom: 2px solid #ccc;
  box-sizing: border-box;
}
.edr-tab-headers .nav-link {
  border-bottom: 2px solid #ccc;
  box-sizing: border-box;
  margin-bottom: -2px;
  color: rgb(204, 204, 204);
  cursor: pointer;
}
.edr-tab-headers .active {
  border-bottom: 2px solid rgb(128, 45, 200);
  margin-bottom: -2px;
  color: rgb(128, 45, 200);
}
</style>
